import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import tw, { styled, css } from "twin.macro"

import { Button as StyledButton } from "../Button/Button"
import { StyledColumn } from "../../styled/Column"
import { StyledRow } from "../../styled/Row"
import { H1, H2, P } from "../../styled/Text"

export { StyledCard as Card } from "../../styled/Card"
export { StyledContainer as Container } from "../../styled/Container"
export { StyledGridColumn as GridColumn } from "../../styled/Grid"
export { StyledResponsive as Responsive } from "../../styled/Responsive"

export const Image = tw(GatsbyImage)`block w-full mb-3 rounded-lg`
export const DateTime = tw.time`block font-bold text-xxs leading-none uppercase tracking-wide text-green mb-7`
export const Title = tw(H1)`mb-0 md:mb-4 font-bold lg:text-3xl`
export const Subtitle = styled(H2)`
  ${tw`mt-2 md:mt-0 mb-6 font-bold text-lg lg:text-lg`}
  ${({ noMargin }) => noMargin && tw`mb-0`}
  ${({ topMargin }) => topMargin && tw`mt-8`}
`
export const Sponsor = tw.span`text-grey-light block md:inline mt-4 md:mt-0`
export const Location = tw.div`flex items-center mt-4 text-xs text-grey-light leading-normal mb-10`
export const LocationText = tw.div`ml-2`
export const CTABar = tw.div`flex md:hidden text-center shadow-lg-bottom z-10 p-6 bg-grey-lightest fixed bottom-0 inset-x-0  justify-center`
export const Actions = tw.div`flex`
export const Button = tw(StyledButton)`lg:px-12 text-center inline-block mr-4`
export const Row = tw(StyledRow)`md:-mx-4 lg:-mx-8 md:w-auto md:mb-12`
export const Column = tw(StyledColumn)`md:px-4 lg:px-8 mb-6 md:mb-0`
export const BackLink = tw(Link)`flex items-center justify-center my-5`
export const BackLinkText = tw(P)`text-base ml-4 font-bold`
export const WishlistButton = styled.button`
  ${tw`absolute top-8 right-8 focus:outline-none hover:text-pink`}
  ${({ active }) => (active ? tw`text-pink` : tw`text-grey-mid`)}
`
export const MobileWishlistButton = styled.button`
  ${tw`focus:outline-none`}
  ${({ active }) => (active ? tw`text-pink` : tw`text-grey-mid`)}
`
