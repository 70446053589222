import tw, { styled } from "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"

import { H3, P } from "../../styled/Text"
import { StyledCard } from "../../styled/Card"

export { StyledRow as Row } from "../../styled/Row"
export { StyledColumn as Column } from "../../styled/Column"

export const Card = styled(StyledCard)`
  ${tw`text-left md:py-13 md:px-12 lg:px-24 shadow-none`}
  ${({ large }) => (large ? tw`lg:pl-16 font-bold` : tw`mt-20 md:mt-0`)}
`
export const Image = styled(GatsbyImage)`
  ${tw`block rounded-full w-34 md:w-full max-w-full mb-6 md:mb-0 md:mr-12 lg:mr-24`}
  ${({ large }) => (large ? tw`lg:mr-16` : tw`-mt-24 md:mt-0`)}
`
export const Quote = tw(H3)`mb-4 md:mb-16 font-medium`
export const Title = styled(Quote)`
  ${tw`font-medium md:mb-8`}
  ${({ large }) => (large ? tw`font-bold` : tw`text-center italic md:not-italic mb-8 md:text-left`)}
`
export const Content = styled(P)`
  ${tw`text-grey-dark`}
  ${({ large }) => !large && tw`text-center md:text-left`}
`
