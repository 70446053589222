import React, { useCallback, useEffect, useMemo, useState } from "react"

import { useCore } from "../../hooks/useCore"
import { useApp } from "../../hooks/useApp"
import { useTemplate } from "../../hooks/useTemplate"
import { useFunctions } from "../../hooks/useFunctions"
import { useSanityEvent } from "../../hooks/useSanity"
import { useKlaviyo } from "../../hooks/useKlaviyo"
import { useExistsInSavedItems, useToggleSavedItemsProduct } from "../../hooks/useSavedItems"

export const withEventDetails = Component =>
  React.memo(({ name = "EventDetails", page, event, live, loading, ...props }) => {
    const {
      helpers: { sanityContent },
    } = useCore()
    const { customer, setActivePopup, setActiveVideo, setRedirectUrl, member } = useApp()
    const { eventCancel } = useFunctions()
    const { event: template } = useTemplate()
    const { getRelatedEvents, related } = useSanityEvent()
    const { trackEvent } = useKlaviyo()
    const [canceling, setCanceling] = useState<boolean>(false)
    const [attending, setAttending] = useState<boolean>(false)

    const title = page?.title
    const content = sanityContent(page?.content)
    const past = new Date(event?.date) && new Date(event?.date).getTime() < new Date().getTime()

    useEffect(() => {
      setAttending(live?.length ? live?.filter(item => item?._id == event?.id)?.[0]?.attending : event?.attending)      
    }, [live, event])

    useEffect(() => {
      getRelatedEvents(event, 8)
    }, [event])

    const handleUpgrade = useCallback(
      (show, type = "register") => {
        setActivePopup(show)
        if (type === "register") setRedirectUrl(`/events/${event?.slug?.current}/register`)
        if (type == "video") setRedirectUrl(`/events/${event?.slug?.current}`)
      },
      [setActivePopup]
    )

    const handleCancel = async () => {
      setCanceling(true)
      const success = await eventCancel({ id: event?.id, member })
      if (success) {
        setAttending(false)
      }
      setCanceling(false)
    }

    const handlePlay = useCallback(video => setActiveVideo(video), [setActiveVideo])

    trackEvent("Viewed Event", event)

    const { addToSavedItems, deleteFromSavedItems } = useToggleSavedItemsProduct()
    const { existsInSavedItems } = useExistsInSavedItems()
    const isExistsInSavedItems = existsInSavedItems(event?.id)

    const handleSavedItem = e => {
      e.preventDefault()
      if (isExistsInSavedItems) {
        deleteFromSavedItems(event?.id)
      } else {
        addToSavedItems({
          content: event?.summary,
          id: event?.id,
          title: event?.title,
          type: "event",
          image: {
            src: event?.image.asset.url,
          },
          link: `/events/${event?.slug.current}`,
        })
      }
    }

    const isLoggedIn = customer != null

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          {...props}
          {...page}
          title={title}
          content={content}
          event={event}
          template={template}
          video={event?.video}
          events={related}
          attending={attending}
          past={past}
          member={member?.membership?.status}
          vip={member?.membership?.vip}
          handlePlay={handlePlay}
          handleUpgrade={handleUpgrade}
          handleCancel={handleCancel}
          loading={loading}
          canceling={canceling}
          handleSavedItem={handleSavedItem}
          existsInSavedItems={isExistsInSavedItems}
          isLoggedIn={isLoggedIn}
        />
      ),
      [attending, loading, canceling, related, customer, isExistsInSavedItems]
    )
  })
