import React from "react"
import { graphql } from "gatsby"

import { EventDetails as Template } from "../components/Event/EventDetails"
import { useSanityEvent } from "../hooks/useSanity"
import { useApp } from "../hooks/useApp"

export const query = graphql`
  query($id: String!) {
    event: sanityEvent(_id: { eq: $id }) {
      ...GatsbyEventFragment
    }
    # videos: allSanityVideo {
    #   edges {
    #     node {
    #       ...GatsbyVideoFragment
    #     }
    #   }
    # }
    # template: sanityTemplateEvent {
    #   additionalRelatedText
    #   additionalShareText
    #   settingShowRelated
    #   settingShowSocialShare
    # }
    # TODO: LIVE QUERY FOR RELATED EVENTS
  }
`

export default ({ data, ...props }): JSX.Element => {
  const { ready } = useApp()
  const { events, loadingLiveEvents, getEvents } = useSanityEvent()
  if (ready && !events?.length) getEvents({ ids: [data?.event?.id] })
  return <Template {...props} {...data} live={events} loading={loadingLiveEvents} />
}
