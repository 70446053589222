import React from "react"
import { Link } from "gatsby"

import { withEventDetails } from "./withEventDetails"
import { EventCard } from "./EventCard"
import { getFluidGatsbyImage, routeResolver } from "../Wrappers/Helpers"

import { RichText } from "../RichText/RichText"
import { CardBlockquote } from "../Cards/CardBlockquote"
import { Grid } from "../Grid/Grid"
import { Hero } from "../Section/Hero/Hero"
import { CallToAction } from "../CallToAction/CallToAction"
import { Icon } from "../Icon/Icon"
import { StyledSpinner, StyledCircle } from "../../styled/Spinner"

import {
  Container,
  Responsive,
  GridColumn,
  Card,
  Image,
  DateTime,
  Title,
  Subtitle,
  Sponsor,
  Location,
  LocationText,
  CTABar,
  Button,
  Row,
  Column,
  BackLink,
  BackLinkText,
  Actions,
  WishlistButton,
  MobileWishlistButton,
} from "./EventDetails.styled"

export const EventDetails = withEventDetails(
  ({
    event: { additionalContent, content, date, price, host, image, location, registration, viponly, freeevent, sponsor, slug, title },
    events,
    video,
    attending,
    past,
    vip,
    member,
    loading,
    canceling,
    template: {
      about,
      aboutHost,
      back,
      callToActionLink,
      callToActionSubtitle,
      callToActionTitle,
      register,
      relatedAll,
      relatedTitle,
      showRelated,
      sponsor: sponsoredBy,
    },
    handlePlay,
    handleUpgrade,
    handleCancel,
    handleSavedItem,
    existsInSavedItems,
    isLoggedIn,
  }: Props): JSX.Element => (
    <>
      <Responsive screen={`md>`}>
        <Hero colour={`secondary`} size={`small`} extended backLink={"/events"} />
      </Responsive>
      <Responsive screen={`<md`}>
        <BackLink as={Link} title={back} theme={`tertiary`} to={"/events"}>
          <Icon name={`chevronLeft`} size={`s`} colour={`tertiary`} />
          <BackLinkText>{back}</BackLinkText>
        </BackLink>
      </Responsive>
      <Container collapse={`<md`} position={`relative`} width={`xl`} spacing={`md`}>
        <Card size={`full`}>
          <Row wrap>
            <Column width={`md:w-1/2`}>{image && <Image alt={image.alt} image={{ ...getFluidGatsbyImage(image, { maxWidth: 1000 }) }} />}</Column>
            <Column width={`md:w-1/2`}>
              <Row>
                <Column width={`md:w-1/2`}>
                  {date && (
                    <DateTime>
                      {new Intl.DateTimeFormat(`en-AU`, {
                        day: `2-digit`,
                        month: `long`,
                        year: `numeric`,
                        timeZone: `Australia/Melbourne`,
                      }).format(Date.parse(`${new Date(date)}`))}
                      {/* {` • ${new Intl.DateTimeFormat(`en-AU`, {
                        hour: `2-digit`,
                        minute: `2-digit`,
                        timeZone: `Australia/Melbourne`,
                      }).format(Date.parse(`${new Date(date)}`))}`} */}
                    </DateTime>
                  )}
                </Column>
                {isLoggedIn && (
                  <Column width={`md:w-1/2`} position={`right`}>
                    <Responsive screen={"md>"}>
                      <WishlistButton active={existsInSavedItems} onClick={handleSavedItem}>
                        <Icon name={`heart`} size={`ml`} />
                      </WishlistButton>
                    </Responsive>
                    <Responsive screen={"<md"}>
                      <MobileWishlistButton active={existsInSavedItems} onClick={handleSavedItem}>
                        <Icon name={`heart`} size={`ml`} />
                      </MobileWishlistButton>
                    </Responsive>
                  </Column>
                )}
              </Row>
              <Title>{title}</Title>
              <Responsive screen={`md>`}>
                {location?.length && (
                  <Location>
                    <Icon name={`pin`} size={`m`} colour={`grey-light`} />
                    <LocationText>{location}</LocationText>
                    {price && (
                      <>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Icon name={`badge`} size={`m`} colour={`grey-light`} />
                        <LocationText>${price}</LocationText>
                      </>
                    )}
                  </Location>
                )}
                <Actions>
                  {registration && !past && !loading ? (
                    <>
                      <Button
                        size={`large`}
                        theme={`primary`}
                        colour={`dark`}
                        title={register}
                        as={!attending && ((!viponly && member) || (viponly && vip) || freeevent) ? Link : null}
                        to={!attending && ((!viponly && member) || (viponly && vip) || freeevent) ? `/events/${slug?.current}/register` : null}
                        onClick={() => ((!member || (viponly && !vip)) && !freeevent ? handleUpgrade(true, "register") : null)}
                        disabled={attending}
                      >
                        {!attending ? register : "You're going!"}
                      </Button>
                      {attending && (
                        <Button
                          theme={`primary`}
                          colour={canceling ? `dark` : `outline`}
                          size={`large`}
                          loading={canceling}
                          onClick={() => handleCancel()}
                        >
                          Cancel
                        </Button>
                      )}
                    </>
                  ) : video ? (
                    <Button
                      size={`large`}
                      theme={`primary`}
                      colour={`dark`}
                      title={"Watch Replay"}
                      as={null}
                      onClick={!member ? () => handleUpgrade(true, "video") : () => handlePlay(video)}
                    >
                      Watch Replay
                    </Button>
                  ) : null}
                </Actions>
              </Responsive>
            </Column>
          </Row>
          {(content || additionalContent) && (
            <Row wrap>
              <Column width={`md:w-1/2`}>
                <Responsive screen={`md>`}>
                  <Subtitle>{about}</Subtitle>
                </Responsive>
                {content && <RichText>{content}</RichText>}
              </Column>
              <Column width={`md:w-1/2`}>{additionalContent && <RichText>{additionalContent}</RichText>}</Column>
            </Row>
          )}
        </Card>
      </Container>

      {host?.name && host?.image && host?.biography && (
        <Container condense={`<md`} spacing={`md`} topSpacing={`md`} width={`xl`}>
          <CardBlockquote image={getFluidGatsbyImage(host?.image, { maxWidth: 800 })} title={aboutHost?.replace(`{name}`, host?.name)}>
            <RichText>{host?.biography}</RichText>
          </CardBlockquote>
        </Container>
      )}

      {callToActionTitle && callToActionSubtitle && (
        <CallToAction
          title={callToActionTitle}
          mainContent={callToActionSubtitle}
          bgColour={`primary`}
          buttonText={callToActionLink?.title}
          buttonLink={routeResolver({ item: callToActionLink?.document })}
          buttonTheme={`primary`}
          buttonColour={`light`}
          buttonSize={`large`}
          collapse
        />
      )}

      {sponsor && (
        <Responsive screen={`<md`}>
          <Container spacing={`md`}>
            <Subtitle>
              {sponsoredBy}&nbsp;<Sponsor>{sponsor}</Sponsor>
            </Subtitle>
          </Container>
        </Responsive>
      )}

      {showRelated && events?.length > 0 && (
        <Container collapse={`<md`} spacing={`md`} topSpacing={`md`} width={`xl`}>
          <Grid show={3} title={relatedTitle} viewMoreLink={"/events"} viewMoreButton={relatedAll} viewMoreArrow>
            {events?.map(({ date, id, image, location, summary, title, slug, ...item }) => (
              <GridColumn key={id}>
                <Responsive screen={`md>`}>
                  <EventCard size={`full`} image={image} title={title} content={summary} link={`/events/${slug?.current}`} date={date} />
                </Responsive>
                <Responsive screen={`<md`}>
                  <EventCard size={`full`} image={image} title={title} content={summary} link={`/events/${slug?.current}`} date={date} />
                </Responsive>
              </GridColumn>
            ))}
          </Grid>
        </Container>
      )}

      {sponsor && (
        <Responsive screen={`md>`}>
          <Container spacing={`md`} width={`xl`}>
            <Subtitle>
              {sponsoredBy}&nbsp;<Sponsor>{sponsor}</Sponsor>
            </Subtitle>
          </Container>
        </Responsive>
      )}

      {(registration && !past) || video ? (
        <CTABar>
          <Actions>
            {registration && !past && !loading && (
              <>
                <Button
                  size={`medium`}
                  theme={`primary`}
                  colour={`dark`}
                  title={register}
                  as={!attending && ((!viponly && member) || (viponly && vip) || freeevent) ? Link : null}
                  to={!attending && ((!viponly && member) || (viponly && vip) || freeevent) ? `/events/${slug?.current}/register` : null}
                  onClick={() => ((!member || (viponly && !vip)) && !freeevent ? handleUpgrade(true, "register") : null)}
                  disabled={attending}
                >
                  {!attending ? register : "You're going!"}
                </Button>
                {attending && (
                  <Button theme={`primary`} colour={canceling ? `dark` : `outline`} size={`large`} loading={canceling} onClick={() => handleCancel()}>
                    Cancel
                  </Button>
                )}
              </>
            )}
            {video ? (
              <Button
                size={`medium`}
                theme={`primary`}
                colour={`dark`}
                title={"Watch Video"}
                as={null}
                onClick={!member ? () => handleUpgrade(true) : () => handlePlay(video)}
              >
                Watch Video
              </Button>
            ) : null}
          </Actions>
        </CTABar>
      ) : null}
    </>
  )
)

export interface Props {
  event: {
    additionalContent: object
    content: any
    date: string
    price: number
    events: Array<any>
    host: {
      name: string
      biography?: string
      image?: any
    }
    image: any
    location: string
    registration: string
    sponsor: string
    slug: {
      current: string
    }
    title: string
    type: string
    viponly?: boolean
  }
  events?: Array<any>
  video?: any
  attending?: boolean
  past?: boolean
  vip?: boolean
  freeevent?: boolean
  member?: boolean
  template?: any
  handlePlay?: (video: any) => void
  handleUpgrade?: (shoe: boolean, type: string) => void
  handleCancel?: () => void
  handleSavedItem?: (any) => void
  existsInSavedItems?: boolean
  isLoggedIn?: boolean
  canceling?: boolean
}
