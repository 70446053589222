import React from "react"
import { getFluidGatsbyImage } from "../Wrappers/Helpers"

import { Row, Column, Card, Image, Title, Content } from "./CardBlockquote.styled"

export const CardBlockquote = ({ children, image, title, quote }: Props): JSX.Element => (
  <Card as={`blockquote`} large={title && !quote} size={`full`} shadow={"medium"}>
    <Row wrap>
      {image && (
        <Column position={`center`} width={`md:w-1/4`}>
          <Image image={image} large={title && !quote} />
        </Column>
      )}

      <Column width={image && `md:w-3/4`}>
        {quote ? <Title>{`“${quote}“`}</Title> : null}
        {title ? <Title large>{title}</Title> : null}
        <Content large={title && !quote}>{children}</Content>
      </Column>
    </Row>
  </Card>
)

export interface Props {
  image?: any
  title?: string
  quote?: string
  children?: any
}
